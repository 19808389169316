@import "var.conf";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg {
      width: 100%;
      height:auto;
      line-height: 2;
      background-color: #fff;
      #MainImgInner {
        position: relative;
        width: 100%;
        background-image: url(../img/contents/top/mainimage.jpg);
        background-position: center center;
        background-size: 1280px auto;
        img {
          display: none;
        }
        h2 {
          position: absolute;
          left:60px;
          bottom: 30px;
          text-align: left;
          font-size: 24px;
          letter-spacing: 0.15em;
          font-weight: 500;
          color: #054391;
        }
        img {
          width: 100%;
        }
      }
    }
    #Main {
      #ContBox01 {
        width: 100%;
        padding-top: 60px;
        padding-bottom: 80px;
        background-color: #122953;
        h3 {
          margin-bottom: 48px;
          color: #fff;
        }
        ul {
          font-size: 0;
          li {
            display: inline-block;
            margin-right: 19px;
            text-align: center;
            font-size: 14px;
            vertical-align: top;
            a {
              display: block;
              padding-bottom: 25px;
              background-color: #fff;
              color: #666;
              text-decoration: none;
              &:hover {
                opacity: 0.7;
              }
              img {
                margin-bottom: 19px;
              }
              strong {
                margin-bottom: 10px;
                display: inline-block;
                font-size: 22px;
                color: #000;
              }
            }
            &:nth-of-type(4){
              margin-right: 0;
            }
          }
        }
      }
      #ContBox02 {
        margin-bottom: 79px;
        padding-top: 60px;
        h3{
          margin-bottom: 48px;
          letter-spacing: 0.09em;
        }
        dl{
          padding: 15px 0;
          border-bottom: #efefef solid 1px;
          line-height: 1.75;
          dt{
            display: inline-block;
            width: 140px;
            font-size: 14px;
            font-family: "Open Sans", serif;
            vertical-align: top;
            color: #666;
          }
          dd {
            display: inline-block;
          }
          &:nth-of-type(1){
            border-top: #efefef solid 1px;
          }
        }
      }
    }
  }


@media print,
screen and  ( max-width:1279px) {
  #Page.pageIndex {
    #MainImg{
      #MainImgInner {
        min-width: 1000px;
        height: 500px;
      }
    }
  }


}
@media print,
screen and (min-width: 1280px) {
  #Page.pageIndex {
    #MainImg {
      #MainImgInner {
        background: none;
        img {
          display: block;
        }
      }

    }
  }
}

@media print,
screen and (max-width: 1000px) {
  #Page.pageIndex {
    #ContBox01 {
      ul {
        margin: 0 auto;
        width: 1000px;
        li {
          width: 235px;
        }
      }
    }
  }

}

@media screen and (min-width:1001px) and ( max-width:1040px) {
  #Page.pageIndex {
    #ContBox01 {
     padding-left: calc((100% - 1000px) / 2) ;
      padding-right: calc((100% - 1000px) / 2) ;
      ul {
        width: 100%;
        min-width: 1000px;
        li{
          width: 23.5%;
        }
      }
    }
  }
}

@media print,
screen and (min-width:1041px) {
  #Page.pageIndex {
    #ContBox01 {
      ul {
        margin: 0 auto;
        width: calc(100% - 40px) ;
        min-width: 1000px;
        li{
          width: calc((100% - 60px) / 4 ) ;
        }
      }
    }
  }
}

  /*
  臨床試験
  -------------------------------------*/
  #PageClinical.pageIndex {
    #MainImg{
      position: relative;
      height: 500px;
      min-width: 1000px;
      background-color: #ffffff;
      #MainImgInner{
      h2{
        position: absolute;
        top: 290px;
        left: 0;
        width: 342px;
        height: 120px;
        background-color:#122953;
        line-height: 120px;
      }
      #ImageBox{
        position: absolute;
        top: 0;
        left: 300px;
        width : -webkit-calc(100% - 300px) ;
        width : calc(100% - 300px) ;
        height: 500px;
        background-image: url(../img/contents/clinical/mainimage.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
     }
      .innerBasic{
        width: auto;
      }
    }
    #Container{
      position: relative;
        #TopicPath{
          position: absolute;
          top: -92px;
        }
        #Main {
          padding-top: 140px;
          .contBox{
            margin-bottom: 135px;
          }
        table{
          margin:27px 0 52px;
          line-height: 1.75;
          caption {
            margin-bottom: 7px;
            text-align: left;
            color: #666666;
          }
          th{
            width: 236px;
            padding:10px 20px 9px;
            background-color: #f0f6ff;
            border-bottom:#dae3f1 solid 1px;
            font-weight: normal;
          }
          td{
            padding:10px 20px 9px;
            border-bottom:#e8e8e8 solid 1px;
          }
          tr{
            &:last-child{
              th,td{
                border-bottom:none;
              }
            }
          }
        }
        #ContBox01{
          figure {
            margin-bottom: 35px;
          }
        }
        #ContBox02{
          margin-bottom:130px;
          figure{
            margin-bottom: 35px;
          }
        }
        #ContBox03 {
          margin-bottom: 125px;
          ol{
            list-style: none;
            font-size: 0;
            li{
              display: inline-block;
              width: 300px;
              margin-right: 49px;
              padding-top: 205px;
              vertical-align: top;
              strong{
                font-size: 22px;
                line-height: 1.4;
                letter-spacing: 0.03em;
                color: #5a8ad2;
              }
              span{
                margin-right: 3px;
                font-family: "Open Sans", serif;
                font-size: 28px;
              }
              p{
                margin-top: 13px;
                font-size: 16px;
              }
              &:nth-of-type(1){
                background: url(../img/contents/clinical/img_03.jpg) center top no-repeat;
              }
              &:nth-of-type(2){
                background: url(../img/contents/clinical/img_04.jpg) center top no-repeat;
              }
              &:nth-of-type(3){
                margin-right: 0;
                background: url(../img/contents/clinical/img_05.jpg) center top no-repeat;
                strong{
                  display: inline-block;
                  padding-left: 30px;
                  padding-right: 3px;
                  text-indent: -30px;
                }
              }
          }
        }
        }
        #ContBox04{
          margin-bottom: 102px;
          h4{
            margin-bottom: 40px;
            height: 44px;
            border: none;
            background-color: #072863;
            text-align: center;
            line-height: 44px;
            color: #fff;
          }
          ul{
            font-size: 0;
            li{
              display: inline-block;
              width: 480px;
              margin-bottom: 20px;
              padding-top: 215px;
              vertical-align: top;
              text-align: center;
              strong{
                font-size: 22px;
                line-height: 1.4;
                color: #5a8ad2;
              }
            p{
              margin-top: 10px;
              text-align: center;
              font-size: 16px;
            }
              &:nth-of-type(1){
                margin-right: 39px;
                background: url(../img/contents/clinical/img_06.jpg) center top no-repeat;
              }
              &:nth-of-type(2){
                background: url(../img/contents/clinical/img_07.jpg) center top no-repeat;
              }
              &:nth-of-type(3){
                margin-right: 39px;
                background: url(../img/contents/clinical/img_08.jpg) center top no-repeat;
              }
              &:nth-of-type(4){
                background: url(../img/contents/clinical/img_09.jpg) center top no-repeat;
              }
            }
          }
        }
        #ContBox05{
          margin-bottom: 200px;
          #ContSubBox01,#ContSubBox02{
            margin-bottom: 79px;
          }
        }
    }
  }
  }


  /*
  in vitro
  -------------------------------------*/
  #PageInvitro {
    #Main {
        table{
          line-height: 1.75;
          caption {
            margin-bottom: 13px;
            text-align: left;
            strong {
              font-weight: bold;
            }
          }
          th{
            width: 236px;
            padding:10px 20px 9px;
            background-color: #f0f6ff;
            border-bottom:#dae3f1 solid 1px;
            font-weight: normal;
          }
          td{
            padding:10px 20px 9px;
            border-bottom:#e8e8e8 solid 1px;
          }
          tr{
            &:last-child{
              th,td{
                border-bottom:none;
              }
            }
          }
        }
    }
  }
  #PageInvitro.pageIndex{
    #MainImg{
      position: relative;
      height: 500px;
      min-width: 1000px;
      background-color: #ffffff;
      #MainImgInner{
      h2{
        position: absolute;
        top: 290px;
        left: 0;
        width: 342px;
        height: 120px;
        background-color:#122953;
        line-height: 120px;
      }
      #ImageBox{
        position: absolute;
        top: 0;
        left: 300px;
        width : -webkit-calc(100% - 300px) ;
        width : calc(100% - 300px) ;
        height: 500px;
        background-image: url(../img/contents/in-vitro/mainimage.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
     }
      .innerBasic{
        width: auto;
      }
    }
    #Container{
      position: relative;
      padding-top: 140px;
      #TopicPath{
        position: absolute;
        top: -92px;
      }
    }
    #Main{
      #ContBox01{
        margin-bottom: 187px;
        h3{
          margin-bottom: 78px;
        }
        p{
          color: #5a8ad2;
        }
        ul{
          font-size: 0;
          margin-bottom: 35px;
          li{
            display: inline-block;
            margin-bottom: 3px;
            width: 324px;
            vertical-align: top;
            a{
              display: block;
              padding-top: 200px;
              text-decoration: none;
              &:hover{
                opacity: 0.7;
              }
              p{
                display: block;
                height: 150px;
                padding-top: 25px;
                border: #dddddd solid 1px;
                border-top: none;
                text-align: center;
                font-size: 14px;
                color: #666;
                strong{
                  font-size: 22px;
                  color: #000;
                }
              }
            }
            &:nth-of-type(1){
              margin-right: 13px;
              a{
                background: url(../img/contents/in-vitro/img_01.jpg) center top no-repeat;
              }
            }
            &:nth-of-type(2){
              margin-right: 13px;
              a{
                background: url(../img/contents/in-vitro/img_02.jpg) center top no-repeat;
              }
            }
            &:nth-of-type(3){
              a{
                background: url(../img/contents/in-vitro/img_03.jpg) center top no-repeat;
              }
            }
            &:nth-of-type(4){
              margin-right: 13px;
              a{
                background: url(../img/contents/in-vitro/img_04.jpg) left top no-repeat;
              }
            }
            &:nth-of-type(5){
              a{
                background: url(../img/contents/in-vitro/img_05.jpg) left top no-repeat;
              }
            }
            &:nth-of-type(4),&:nth-of-type(5){
              width: 493px;
              a{
                padding: 0 0 0 162px;
                p{
                  height: 100px;
                  padding-top: 0;
                  padding-left: 23px;
                  border-left: none;
                  border-top: #dddddd solid 1px;
                  text-align: left;
                  line-height: 100px;
                }
              }
            }
          }
        }
      }
    }
  }
  #PageInvitro.pageCytokine{
    #TopicPath{
      margin-bottom: 103px;
    }
    #Main {
      h3{
        line-height: 0.9;
        span{
          letter-spacing: 0.07em;
        }
      }
      #ContBox01{
        margin-bottom: 238px;
        #ContSubBox02{
          div{
            margin:18px 0 5px 0;
            padding-bottom: 28px;
            background: url(../img/contents/in-vitro/cytokine-img_03.png) bottom center no-repeat;
            figure{
              float: left;
            }
          dl{
            position: relative;
            float: right;
            width: 700px;
            dt{
              margin-bottom: 5px;
              font-weight: bold;
            }
            dd{
              margin-bottom: 3px;
              padding-left: 15px;
              &:before{
                display: block;
                content: "";
                position: absolute;
                left: 0px;
                width: 6px;
                height: 6px;
                margin-top: 9px;
                border-radius: 50%;
                background-color: #072863;
              }
            }
          }
          &:after{
            content: "";
            display: block;
            clear: both;
          }
         }
          p{
            strong{
              display: block;
              margin-bottom: 30px;
              font-size: 20px;
              text-align: center;
              color: #5a8ad2;
            }
          }
        }
      }
    }
    .contSubBox{
      margin-bottom: 72px;
    }
  }
  #PageInvitro.pageNkcells{
    #TopicPath{
      margin-bottom: 103px;
    }
    #Main {
      #ContBox01{
        margin-bottom: 222px;
      }
      h3{
        margin-bottom: 78px;
      }
      table{
        td{
          span{
            font-size: 14px;
            color: #666666;
          }
          p:last-child{
            margin-bottom: 0;
          }
          figure{
            float: right;
            margin-left: 40px;
            padding: 6px 0 30px;
          }
          dl{
            position: relative;
            dt{
              font-weight: bold;
            }
            &.list{
              dd{
                padding-left: 15px;
                &:before{
                  display: block;
                  content: "";
                  position: absolute;
                  left: 0px;
                  width: 6px;
                  height: 6px;
                  margin-top: 9px;
                  border-radius: 50%;
                  background-color: #072863;
                }
              }
            }
          }
        }
        tr{
          &:nth-of-type(2){
            td:nth-of-type(1){
              width: 380px;
              border-right: #e8e8e8 solid 1px;
            }
          }
        }
      }
    }
  }
  #PageInvitro.pageOther{
    #TopicPath{
      margin-bottom: 103px;
    }
    #Main {
      table {
        td {
          span {
            font-size: 14px;
            color: #666;
          }
        }
      }
      #ContBox01{
        margin-bottom: 210px;
      }
      .contSubBox{
        margin-bottom: 79px;
      }
      #ContSubBox02{
        margin-bottom: 64px;
        p{
          margin-bottom: 48px;
        }
      }
      #ContSubBox03,#ContSubBox04{
        h4{
          padding-top: 177px;
          margin-top: -162px;
          margin-bottom: 30px;
        }
      }
      #ContSubBox03{
        margin-bottom: 64px;
        figure{
          float: right;
          margin-left: 40px;
          padding: 6px 0 10px;
        }
      }
      #ContSubBox04{
        figure{
          padding-bottom: 12px;
        }
      }
      table{
        margin-bottom: 56px;
        caption{
          margin-bottom: 12px;
          font-weight: bold;
        }
      }
    }
  }

    /*
  クリニック向け検査
  -------------------------------------*/
  #PageClinics.pageIndex {
    #MainImg{
      position: relative;
      height: 500px;
      min-width: 1000px;
      background-color: #ffffff;
      #MainImgInner{
      h2{
        position: absolute;
        top: 290px;
        left: 0;
        width: 536px;
        height: 120px;
        background-color:#122953;
        line-height: 120px;
      }
      #ImageBox{
        position: absolute;
        top: 0;
        left: 300px;
        width : -webkit-calc(100% - 300px) ;
        width : calc(100% - 300px) ;
        height: 500px;
        background-image: url(../img/contents/clinics/mainimage.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
     }
      .innerBasic{
        width: auto;
      }
    }
    #Container{
      position: relative;
      padding-top: 125px;
      #TopicPath{
        position: absolute;
        top: -92px;
      }
    #Main{
      h3{
        margin-bottom: 70px;
        padding-top: 15px;
      }
      table{
        margin: 34px 0 40px;
        line-height: 1.75;
        th{
          padding:10px 0 9px;
          background-color: #5a8ad2;
          font-weight: normal;
          text-align: center;
          vertical-align: middle;
          color: #fff;
          &:first-child{
            width: 236px;
            border-right:#4e78b7 solid 1px;
          }
        }
        td{
          padding:10px 20px 9px;
          &:first-child{
            background-color: #f0f6ff;
          }
        }
        tr{
          border-bottom:#e8e8e8 solid 1px;
          &:nth-of-type(2){
            td{
              &:first-child{
                border-bottom:#dae3f1 solid 1px;
              }
            }
          }
        }
      }
      p.linkBtn{
        margin: 0 auto;
        width: 420px;
        text-align: center;
        a{
          width: 420px;
          background-image: url(../img/contents/arrow_link03.png);
          background-position: 388px center;
          background-repeat: no-repeat;
        }
      }
      dl{
        width: 485px;
        padding: 25px 36px 34px 35px;
        border: #ebebeb solid 5px;
        line-height: 1.75;
        dt{
          margin-bottom: 6px;
          font-weight: bold;
          color: #5a8ad2;
        }
      }
      .contSubBox{
        clear: both;
        &:after{
          content: "";
          display: block;
          clear: both;
        }
        figcaption {
          text-align: center;
          font-size: 12px;
          color: #666;
        }
      }
      #ContBox01 {
        margin-bottom: 124px;
      }
      #ContBox02{
        padding-top: 162px;
        margin-top: -162px;
        margin-bottom: 126px;
        h3{
          margin-bottom: 78px;
        }
        #ContSubBox01{
          margin-bottom: 79px;
          figure{
            margin-top:30px;
          }
        }
        #ContSubBox02{
          margin-bottom: 76px;
          figure{
            margin-top: 10px;
            figcaption{
              margin-top: 12px;
            }
          }
          dl{
            margin-top: 12px;
          }
        }
        #ContSubBox03{
          margin-bottom: 64px;
          h4{
            margin-bottom: 30px;
          }
        }
        #ContSubBox02,#ContSubBox03{
          figure{
            float: left;
          }
          dl{
            float: right;
          }

        }
      }
      #ContBox03 {
        padding-top: 162px;
        margin-top: -162px;
        margin-bottom: 302px;
        h3{
          margin-bottom: 78px;
        }
        #ContSubBox01{
          margin-bottom: 75px;
          figure{
            margin-top: 33px;
          }
        }
        #ContSubBox02 {
          margin-bottom: 71px;
          div.subBox{
            width: 485px;
            margin-top: 23px;
            span {
              display: inline-block;
              font-size: 14px;
              line-height: 1.75;
              color: #666;
            }
            &:nth-of-type(1){
              float: left;
            }
            &:nth-of-type(2){
              float: right;
            }
            figure {
              margin-bottom: 26px;
              figcaption{
                margin-top: 15px;
              }
            }
          }
        }
        #ContSubBox03 {
          margin-bottom: 67px;
          h4{
            margin-bottom: 28px;
          }
          figure {
            float: left;
          }
          dl{
            float: right;
            margin-top: 1px;
          }
        }
      }
      #ContSubBox04 {
        h4{
          margin-bottom: 30px;
        }
        ol{
          margin-bottom: 64px;
          list-style: none;
          font-size: 0;
          li{
            display: inline-block;
            width: 300px;
            vertical-align: top;
            figure{
              position: relative;
              margin-bottom: 15px;
              figcaption{
                position: absolute;
                top: 0;
                left:0;
                width: 60px;
                height: 22px;
                background-color: #072863;
                font-family: "Open Sans", serif;
                font-size: 14px;
                line-height: 22px;
                color: #fff;
              }
            }
            p{
              width: 300px;
              text-align: center;
              font-size: 18px;
              line-height: 1.6;
            }
            &:nth-of-type(1),&:nth-of-type(2){
              width: 349px;
              background: url(../img/contents/clinics/img_11.png) right 92px no-repeat;
              background-size: 42px 30px;
            }
          }
        }
      }
    }
  }
  }

  #PageClinics.pageHospital {
    #TopicPath{
      margin-bottom: 103px;
    }
    #Main{
      h3{
        margin-bottom: 74px;
      }
      h4{
        margin-bottom:29px;
        padding-top: 15px;
      }
      div.pageLinkBox {
        margin: 45px 0 35px;
        height: 125px;
        padding-top: 25px;
        background-color: #eaedf1;
        ul.pageLink {
          margin-left: 35px;
          font-size: 0;
          li {
            display: inline-block;
            width: 124px;
            margin-bottom: 23px;
            font-size: 16px;
            &:nth-of-type(8) {
              width: 80px;
            }
            &:nth-of-type(16) {
              width: 80px;
            }
            a {
              padding-left: 20px;
              background: url(../img/contents/arrow_01.png) no-repeat left center;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
      div.pageLinkBoxBtm {
        margin-bottom: 160px;
        height: 125px;
        padding-top: 25px;
        background-color: #eaedf1;
        ul.pageLink {
          margin-left: 35px;
          font-size: 0;
          li {
            display: inline-block;
            width: 124px;
            margin-bottom: 23px;
            font-size: 16px;
            &:nth-of-type(8) {
              width: 80px;
            }
            &:nth-of-type(16) {
              width: 80px;
            }
            a {
              padding-left: 20px;
              background: url(../img/contents/arrow_02.png) no-repeat left center;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    .contSubBox {
      padding-top: 162px;
      margin-top: -162px;
      margin-bottom: 66px;
    }
    #ContSubBox16{
      margin-bottom: 66px;
    }
    table{
      line-height: 1.75;
      th{
        padding:0;
        border-right:#4e78b7 solid 1px;
        background-color: #5a8ad2;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        color: #fff;
        &:last-child{
          border-right:none;
        }
      }
      td{
        padding:10px 19px;
        border-right:#e8e8e8 solid 1px;
        vertical-align: middle;
        &:nth-of-type(1){
          width: 300px;
          padding-right: 15px;
        }
        &:nth-of-type(2){
          width: 260px;
        }
        &:nth-of-type(4),
        &:nth-of-type(5){
          width: 129px;
          text-align: center;
          color: #072863;
        }
        a {
          &:after{
            padding-left: 8px;
            content: url(../img/contents/arrow_link.png);
          }
        }
      }
      tr{
        &:nth-of-type(1){
          th{
            &:last-child{
              border-bottom:#4e78b7 solid 1px;
            }
          }
        }
        &:nth-child(even){
          td{
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
  }

  /*
  試験実績
  -------------------------------------*/
  #PageRecord.pageIndex {
    #TopicPath{
      margin-bottom: 35px;
    }
    #Main {
      h3 {
        padding-top: 15px;
      }
      div.pageLinkBox {
        height: 75px;
        padding: 25px 0;
        margin-bottom: 88px;
        background-color: #eaedf1;
      ul.pageLink{
        text-align: center;
        li{
          display: inline-block;
          a{
            padding-left: 20px;
            background: url(../img/contents/arrow_01.png) no-repeat left center;
            &:hover{
              text-decoration: none;
            }
          }
          &:nth-of-type(1){
            margin-right: 70px;
          }
        }
      }
      }
      div.pageLinkBoxBtm {
        height: 75px;
        padding: 25px 0;
        margin-bottom: 235px;
        background-color: #eaedf1;
      ul.pageLink{
        text-align: center;
        li{
          display: inline-block;
          a{
            padding-left: 20px;
            background: url(../img/contents/arrow_02.png) no-repeat left center;
            &:hover{
              text-decoration: none;
            }
          }
          &:nth-of-type(1){
            margin-right: 70px;
          }
        }
      }
      }

      #ContBox01{
        padding-top: 162px;
        margin-top: -162px;
        margin-bottom: 123px;
      }
      #ContBox02{
        padding-top: 162px;
        margin-top: -162px;
        margin-bottom: 88px;
      }
      .contSubBox {
        margin-bottom: 80px;
      }
      h4{
        margin-bottom: 29px;
      }
    table {
      width: 1000px;
      table-layout: fixed;
      border-top: none;
      line-height: 1.75;
      th{
        padding:10px 0 9px;
        border-right:#4e78b7 solid 1px;
        background-color: #5a8ad2;
        font-weight: normal;
        text-align: center;
        vertical-align: middle;
        color: #fff;
        &:nth-of-type(1){
          width: 170px;
        }
        &:nth-of-type(2){
          width: 170px;
        }
        &:nth-of-type(3){
          width: 310px;
        }
        &:nth-of-type(4){
          width: 120px;
          text-align: center;
        }
        &:nth-of-type(5) {
          border-right:none;
        }
      }
      td{
        padding:10px 19px 10px;
        border-right:#e8e8e8 solid 1px;
        vertical-align: middle;
        &:nth-of-type(1){
          padding-bottom: 9px;
          font-size: 14px;
          font-weight: bold;
          white-space: nowrap;
          line-height: 2;
        }
        &:nth-of-type(4){
          text-align: center;
          white-space: nowrap;
        }
        &:nth-of-type(5) {
          border-right:none;
        }
      }
      tr{
        &:nth-of-type(1) {
           border-top: #dddddd solid 1px;
           padding-top: -1px;
        }
        &:nth-child(odd){
          td{
            background-color: #f5f5f5;
          }
        }
      }
    }
  }
  }

  /*
  化粧品試験
  -------------------------------------*/
  #PageCosmetics.pageIndex {
    #MainImg {
      position: relative;
      height: 500px;
      min-width: 1000px;
      background-color: #ffffff;
      #MainImgInner {
        h2 {
          position: absolute;
          top: 290px;
          left: 0;
          width: 388px;
          height: 120px;
          background-color:#122953;
          line-height: 120px;
        }
        #ImageBox {
          position: absolute;
          top: 0;
          left: 300px;
          width : -webkit-calc(100% - 300px) ;
          width : calc(100% - 300px) ;
          height: 500px;
          background-image: url(../img/contents/cosmetics/mainimage.jpg);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .innerBasic {
        width: auto;
      }
    }
    #Container {
      position: relative;
    }
    #TopicPath {
      position: absolute;
      top: -92px;
    }
      #Main {
        #ContBox01 {
          padding-top: 135px;
          margin-bottom: 142px;
          p {
            text-align: center;
            font-size: 22px;
            line-height: 1.5;
          }
          p.linkBtn {
            margin-top: 22px;
            a {
              margin: 0 auto;
              width: 380px;
              padding-left: 20px;
              background-image: url(../img/contents/arrow_link03.png);
              background-position: 347px center;
              background-repeat: no-repeat;
              text-align: left;
              span {
                display: inline-block;
                margin-top: -5px;
                margin-right: 8px;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                background-color: #5a8ad2;
                text-align: center;
                font-size: 14px;
                line-height: 46px;
                vertical-align: middle;
                color: #fff;
              }
            }
          }
        }
        #ContBox02 {
          h3 {
            line-height: 0.9;
            margin-bottom: 76px;
            span {
              letter-spacing: 0.1em;
            }
          }
          .contSubBox {
            &:after {
              content: "";
              display: block;
              clear: both;
            }
          }
          #ContSubBox01 {
            margin-bottom: 62px;
            div.subBox {
              width: 485px;
              &:nth-of-type(1) {
                float: left;
              }
              &:nth-of-type(2) {
                float: right;
                margin-top: 5px;
                padding-top: 53px;
                height: 146px;
                border: #ebebeb solid 5px;
                p {
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                }
              }
            }
          }
          #ContSubBox02 {
            margin-bottom: 128px;
            h4 {
              margin-bottom: 28px;
            }
            figure {
              display: inline-block;
              width: 485px;
              vertical-align: top;
              font-size: 0;
              &:nth-of-type(1) {
                margin-right: 21px;
              }
              figcaption {
                margin-top: 15px;
                width: 485px;
                p {
                  font-weight: bold;
                }
                ul {
                  li {
                    position: relative;
                    margin-bottom: 3px;
                    padding-left: 15px;
                    font-size: 16px;
                    &:before {
                      position: absolute;
                      display: block;
                      content: "";
                      left: 0;
                      width: 6px;
                      height: 6px;
                      margin-top: 9px;
                      border-radius: 50%;
                      background-color: #072863;
                    }
                  }
                }
              }
            }
          }
        }
        #ContBox03 {
          margin-bottom: 202px;
          h3 {
            margin-bottom: 75px;
          }
          #ContSubBox01 {
            margin-top: 72px;
            margin-bottom: 76px;
            h4 {
              margin-bottom: 29px;
            }
            ol {
              margin-top: -150px;
              font-size: 0;
              li {
                display: inline-block;
                width: 199px;
                padding-top: 170px;
                border-right: #e9e9e9 solid 1px;
                vertical-align: top;
                p {
                  padding: 0 19px;
                  font-size: 14px;
                }
                &:nth-of-type(1) {
                  border-left: #e9e9e9 solid 1px;
                }
              }
            }
          }
          #ContSubBox02 {
            margin-bottom: 77px;
            ul {
              li {
                position: relative;
                margin-bottom: 8px;
                padding-left: 26px;
                &:before {
                  position: absolute;
                  top: 4px;
                  left:0;
                  content: url(../img/contents/arrow_03.png);
                }
              }
            }
          }
        }
      }
  }

  #PageCosmetics.pageMonitor {
    #TopicPath{
      margin-bottom: 101px;
    }
    #Main {
      #ContBox01{
        margin-bottom: 239px;
        .contSubBox{
          margin-bottom: 72px;
        }
        #ContSubBox03{
          table{
            margin-top: 33px;
            line-height: 1.75;
            th{
              width: 236px;
              padding: 10px 20px 9px;
              background-color: #f0f6ff;
              border-bottom:#dae3f1 solid 1px;
              font-weight: normal;
            }
            td{
              padding:10px 20px 9px;
              border-bottom:#e8e8e8 solid 1px;
              ol{
                margin-top: -10px;
              }
              span {
                font-size: 14px;
              }
            }
            tr{
              &:last-child{
                th,td{
                  border-bottom: none;
                }
              }
            }
          }
          p.linkBtn{
            margin: 14px 0 22px;
            a{
              width: 540px;
              padding: 0 0 0 66px;
              background-image: url(../img/contents/cosmetics/monitor-img_01.png),url(../img/contents/arrow_link.png);
              background-position: 15px center,510px center;
              background-repeat: no-repeat,no-repeat;
            }
          }

        }
      }
    }
  }

  /*
  会社概要
  -------------------------------------*/
  #PageCompany.pageIndex {
    #Main {
      #ContBox01{
        margin-bottom: 138px;
        table{
          border:none;
          border-top:#e8e8e8 solid 1px;
          line-height: 1.75;
          th{
            padding:15px 0 14px;
            width: 220px;
            border-bottom:#e8e8e8 solid 1px;
          }
          td{
            padding:15px 20px 14px;
            border-bottom:#e8e8e8 solid 1px;
            ul {
              li{
                margin-bottom: 4px;
                a {
                  &:after {
                    padding-left: 8px;
                    content: url(../img/contents/arrow_link.png);
                  }
                }
                &:last-child{
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    #ContBox02{
      margin-bottom: 138px;
      table{
        line-height: 1.75;
        border:none;
        tr{
          &:nth-child(odd){
            background-color: #f5f5f5;
          }
        }
        th{
          padding:15px 30px 15px;
          width: 220px;
          font-weight: normal;
        }
        td{
          padding:15px 20px 15px;
        }
      }
    }
    #ContBox03 {
      margin-bottom: 140px;
      h3{
        margin-bottom: 70px;
      }
      p{
        margin-bottom: 20px;
        text-align: center;
        line-height: 2;
        &.image{
          margin-top:50px;
        }
      }
    }
    #ContBox04 {
      margin-bottom: 227px;
      h3{
        margin-bottom: 75px;
      }
      section.contSubBox {
        margin-bottom: 67px;
        &:after{
            content: "";
            display: block;
            clear: both;
        }
        h4{
          margin-bottom: 30px;
        }
      }
      div.mapBox{
        float: left;
        margin-right: 50px;
        width: 640px;
        div.gMapBox{
          margin-bottom: 8px;
          width: 640px;
          height: 320px;
          background-color: #888;
        }
        p{
          font-size: 14px;
          text-align: right;
        }
      }
      dl {
        float: right;
        width: 310px;
        margin-top: -5px;
        dt {
          margin-bottom: 8px;
          font-weight: bold;
          &:nth-of-type(2){
            margin-top: 20px;
          }
        }
        dd {
          position: relative;
          padding-left: 27px;
          p {
            margin-bottom: 0px;
            font-size: 14px;
            span {
              font-size: 16px;
            }
            a {
              &:after {
                padding-left: 8px;
                content: url(../img/contents/arrow_link.png);
              }
            }
          }
          &:nth-of-type(1) {
            &:before {
              position: absolute;
              top: 3px;
              left: 0px;
              content: url(../img/contents/company/arrow_car.png);
            }
          }
          &:nth-of-type(2) {
            &:before {
              position: absolute;
              top:4px;
              left: 0px;
              content: url(../img/contents/company/arrow_train.png);
            }
          }
          &:nth-of-type(3) {
            &:before {
              position: absolute;
              top:2px;
              left: 0px;
              content: url(../img/contents/company/arrow_car.png);
            }
          }
        }
      }
      #ContSubBox02 {
        dl {
          dd{
            &:before{
              position: absolute;
              top: 4px;
              left: 0;
              content: url(../img/contents/company/arrow_walking.png);
            }
          }
        }
      }
    }
  }

  /*
  お問い合わせ
  -------------------------------------*/
  #PageContact.pageIndex {
    #Main {
      #ContBox01{
        margin-bottom: 119px;
        #ContSubBox01{
          margin-bottom: 60px;
          h4{
            margin-bottom: 4px;
            border: none;
            text-align: center;
            font-weight: bold;
            color: #000;
          }
          div.subBox{
            width: 490px;
            &:nth-of-type(1){
              float: left;
              dl{
                height: 443px;
              }
            }
            &:nth-of-type(2){
              float: right;
              dl{
                &:nth-of-type(1){
                  height: 210px;
                  margin-bottom: 53px;
                  dt{
                    margin-bottom: 16px;
                  }
                  dd{
                    p.linkBtn{
                      margin-bottom: 12px;
                      a{
                        padding-left: 20px;
                        background-image: url(../img/contents/arrow_link03.png);
                        background-position: 347px center;
                        background-repeat: no-repeat;
                        text-align: left;
                        span{
                          display: inline-block;
                          margin-top: -5px;
                          margin-right: 8px;
                          width: 46px;
                          height: 46px;
                          border-radius: 50%;
                          background-color: #5a8ad2;
                          text-align: center;
                          line-height: 46px;
                          vertical-align: middle;
                          color: #fff;
                        }
                      }
                    }
                  }
                }
                &:nth-of-type(2){
                  height: 140px;
                  padding-top:35px;
                  dd{
                    a {
                        background-image: url(../img/contents/arrow_link.png);
                        background-position: 350px center;
                        background-repeat: no-repeat;
                    }
                  }
                }
              }

            }
            dl{
              border: #ebebeb solid 5px;
              dt{
                margin: 30px 0 3px;
                text-align: center;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0.2em;
                line-height: 1.3;
                color: #072863;
              }
              dd {
                margin-bottom: 0px;
                text-align: center;
                font-size: 30px;
                span{
                  font-size: 14px;
                }
                p {
                  text-align: center;
                  font-size: 14px;
                  line-height: 2.5em;
                }
                &:nth-of-type(2){
                  margin-bottom: 38px;
                  padding-bottom: 19px;
                  border-bottom: #ebebeb solid 1px;
                }
                p.linkBtn{
                  a{
                    margin: 0 auto;
                    width: 380px;
                  }
                }
              }
            }
          }
          &:after{
            content: "";
            display: block;
            clear: both;
          }
        }
        #ContSubBox02{
          p{
            text-align: center;
            font-size: 14px;
          }
          p.linkBtn{
            margin-bottom: 17px;
            a{
              margin: 0 auto;
              width: 420px;
              border: none;
              background-image: url(../img/contents/arrow_link02.png);
              background-position: 390px center;
              background-repeat: no-repeat;
              background-color: #072863;
              color: #fff;
            }
          }

        }
      }
      #ContBox02{
        padding-top: 162px;
        margin-top: -162px;
        margin-bottom: 205px;
        h3{
          padding-top: 15px;
          margin-bottom: 78px;
        }
        #ContSubBox01{
          margin-bottom: 72px;
        }
        #ContSubBox02 {
          ol{
            margin-bottom: 67px;
            li{
              margin-bottom: 10px;
              padding-left: 21px;
              text-indent: -21px;
              line-height: 1.75;
            }
          }
          p{
          font-size: 14px;
          color: #666;
        }
        }
      }
    }
  }

  /*
  よくある質問
  -------------------------------------*/
  #PageFaq.pageIndex {
    #Main {
      #ContBox01 {
        margin-bottom: 167px;
      ul{
        font-size: 0;
        li {
          display: inline-block;
          width: 324px;
          margin-right: 13px;
          margin-bottom: 14px;
          a {
            display: block;
            padding-top: 200px;
            width: 324px;
            height: 280px;
            text-decoration: none;
            &:hover {
              opacity: 0.7;
            }
            p {
              border:#dddddd solid 1px;
              border-top: none;
              text-align: center;
              font-size: 22px;
              line-height: 79px;
            }
          }
          &:nth-of-type(1) {
            a {
              background: url(../img/contents/faq/img_01.jpg) center top no-repeat;
            }
          }
          &:nth-of-type(2) {
            a {
              background: url(../img/contents/faq/img_02.jpg) center top no-repeat;
            }
          }
          &:nth-of-type(3) {
            margin-right: 0;
            a {
              background: url(../img/contents/faq/img_03.jpg) center top no-repeat;
            }
          }
          &:nth-of-type(4) {
            a {
              background: url(../img/contents/faq/img_04.jpg) center top no-repeat;
            }
          }
          &:nth-of-type(5) {
            a {
              background: url(../img/contents/faq/img_05.jpg) center top no-repeat;
            }
          }
          &:nth-of-type(6) {
            margin-right: 0;
            a {
              background: url(../img/contents/faq/img_06.jpg) center top no-repeat;
            }
          }
        }
      }
     }
    }
  }

  /*
  カテゴリ/詳細アーカイブ
  -------------------------------------*/
  #PageFaq.pageCategory,#PageFaq.pageEntry {
    #Main {
      float: right;
      width: 720px;
      h3 {
        text-align: left;
        margin-bottom: 37px;
        letter-spacing: 0.2em;
      }
      p.linkBtn {
        margin-top: 40px;
        a {
          margin: 0 auto;
          width: 200px;
          height: 50px;
          text-align: center;
          font-size: 14px;
          line-height: 50px;
        }
      }
    }
    #BlogNavi {
      float: left;
      width: 220px;
      padding: 24px 20px 14px;
      background-color: #f7f7f7;
      h2 {
        display: none;
      }
      ul {
        li {
          position: relative;
          margin-bottom: 12px;
          padding-left: 16px;
          font-size: 14px;
          &.current-cat {
            a {
              color: #bdbdbd;
              &:hover {
                opacity: 1;
              }
            }
          }
          &:before {
            position: absolute;
            top:7px;
            content: "";
            left: 0px;
            box-sizing: border-box;
            width: 5px;
            height: 6px;
            border: 4px solid transparent;
            border-left: 7px solid #5a8ad2;
          }
          a {
            text-decoration: none;
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }

  /*
  カテゴリアーカイブ
  -------------------------------------*/
  #PageFaq.pageCategory {
    #Main {
      #ContBox01 {
        margin-bottom: 259px;
        ul {
        border-top: #efefef solid 1px;
        li{
          border-bottom: #efefef solid 1px;
          a{
            display: block;
            width: 720px;
            padding: 19px 0 18px;
            text-decoration: none;
            line-height: 1.85;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      }
    }
  }

  /*
  記事詳細ページ
  -------------------------------------*/
  #PageFaq.pageEntry {
    #Main {
      #ContBox01 {
        margin-bottom: 308px;
        h3 {
          margin-bottom: 25px;
          font-size: 22px;
          line-height: 1.3em;
        }
        div.entryBox {
          padding-bottom: 43px;
          border-bottom: #efefef solid 1px;
        }
      }
    }
  }



@media all and (-ms-high-contrast:none){
  *::-ms-backdrop, #Header #HeaderMenu ul li a {
    padding-top:3px;
  }
  *::-ms-backdrop, #MainImg {
    padding-top: 5px;
  }
  *::-ms-backdrop, #PageClinics.pageIndex #MainImg #MainImgInner h2 {
    padding-top: 5px;
  }
  *::-ms-backdrop, #PageClinical.pageIndex #MainImg #MainImgInner h2 {
    padding-top: 5px;
  }
  *::-ms-backdrop, #PageClinical.pageIndex #Container #Main #ContBox04 h4 {
    padding-top: 3px;
  }
  *::-ms-backdrop, #PageInvitro.pageIndex #MainImg #MainImgInner h2 {
    padding-top: 5px;
  }
  *::-ms-backdrop, #PageCosmetics.pageIndex #MainImg #MainImgInner h2 {
    padding-top: 5px;
  }
  *::-ms-backdrop, #Main p.linkBtn a {
    padding-top:3px;
  }
  *::-ms-backdrop, #PageRecord.pageIndex #Main ul.pageLink li a {
    background: url(../img/contents/arrow_01.png) no-repeat left 6px;
  }
  *::-ms-backdrop, #PageCosmetics.pageIndex #Container #Main #ContBox01 p.linkBtn a span {
    padding-top:3px;
  }
  *::-ms-backdrop, #PageCosmetics.pageMonitor #Main #ContBox01 #ContSubBox03 p.linkBtn a {
    padding-top:3px;
  }
  *::-ms-backdrop, #PageContact.pageIndex #Main #ContBox01 #ContSubBox01 div.subBox:nth-of-type(2) dl:nth-of-type(1) dd p.linkBtn a span {
    padding-top:3px;
  }
  *::-ms-backdrop, #PageRecord.pageIndex #Main ul.pageLink li a {
    background: url(../img/contents/arrow_01.png) no-repeat left 6px;
  }
  *::-ms-backdrop, #PageRecord.pageIndex #Main ul.pageLink:last-child li a {
    background: url(../img/contents/arrow_02.png) no-repeat left 6px;
  }
  *::-ms-backdrop, #PageClinics.pageHospital #Main ul.pageLink li a {
    background: url(../img/contents/arrow_01.png) no-repeat left 6px;
  }
  *::-ms-backdrop, #PageClinics.pageHospital #Main ul.pageLink:last-child li a {
    background: url(../img/contents/arrow_02.png) no-repeat left 6px;
  }

}

  /* end */


